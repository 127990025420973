<template>
  <div class="home">
    <div class="home-banner" id="anchor1">
      <img class="home-banner-img" src="../../assets/img/banner.png" alt="" />
      <div class="home-banner-logo">
        <img src="../../assets/img/logo.png" alt="" />
      </div>
      <div class="flex flex-right home-banner-tag">
        <div
          class="flex flex-center align-center home-banner-tag-item"
          v-for="(item, index) in tagList"
          :key="index"
          :class="tagIdx == index ? 'home-banner-tag-item1' : ''"
          @click="clickTag(index)"
        >
          {{ item.name }}
          <a :href="item.id"></a>
        </div>

        <div class="flex flex-center align-center home-banner-tag-item">|</div>
        <div
          class="flex flex-center align-center home-banner-tag-item home-banner-tag-login"
          @click="clickImg(1)"
        >
          达人登录
        </div>
        <div>
          <a
            class="flex flex-center align-center home-banner-tag-item home-banner-tag-login aLogin"
            href="http://wx.yourenwu.com.cn:8901/"
            target="_blank"
            >企业登录</a
          >
        </div>

        <div></div>
      </div>
      <div class="home-banner-info">
        <img
          class="home-banner-info-img"
          src="../../assets/img/info.png"
          alt=""
        />
      </div>
      <div class="home-banner-info1">
        给企业推荐优质带货人才、为企业打造更多销货方案<br />
        优质服务值得您信赖
      </div>
    </div>
    <div class="home-js" id="anchor4">
      <div class="home-banner-info2">
        <img @click="clickImg(2)" src="../../assets/img/banner1.png" alt="" />
        <img @click="clickImg(2)" src="../../assets/img/banner2.png" alt="" />
      </div>
      <div class="home-banner-info3"></div>
      <img class="home-js-img" src="../../assets/img/gsjs.png" alt="" />
    </div>
    <div class="home-js" id="anchor3">
      <img class="home-js-img" src="../../assets/img/rw.png" alt="" />
    </div>
    <div class="home-js" id="anchor2">
      <img class="home-js-img" src="../../assets/img/zs.png" alt="" />
      <img class="home-js-img" src="../../assets/img/zs1.png" alt="" />
      <img class="home-js-img" src="../../assets/img/zs2.png" alt="" />
      <img class="home-js-img" src="../../assets/img/zs3.png" alt="" />
      <img class="home-js-img" src="../../assets/img/zs4.png" alt="" />
      <div class="flex flex-between home-js-link">
        <a
          class="home-js-link-a1"
          href="http://www.rencaizhongxin.org.cn/"
          target="_blank"
        ></a>
        <a
          class="home-js-link-a2"
          href="https://www.zgzh.org.cn/"
          target="_blank"
        ></a>
        <a
          class="home-js-link-a3"
          href="https://www.msstc.org.cn/"
          target="_blank"
        ></a>
      </div>
    </div>
    <div class="home-js">
      <img class="home-js-img" src="../../assets/img/zwkb.png" alt="" />
    </div>
    <div class="home-js">
      <img class="home-js-img" src="../../assets/img/qdfw.png" alt="" />
    </div>
    <div class="home-fotter">
      <div class="home-fotter-item">
        重庆粟梵科技有限公司 &copy;2022-2023 版权所有 yourenwu.com.cn
        <span @click="clickLinkFunc" style="cursor: pointer"
          >渝ICP备2021012319号-3</span
        >
      </div>
      <div
        class="flex flex-center align-center home-fotter-item"
        style="margin-top: 7px"
      >
        <img
          class="home-fotter-item-icon"
          src="../../assets/img/jc.png"
          alt=""
        />渝公网安备 50010802004624号
      </div>
    </div>
    <!-- 客服 -->
    <div class="service">
      <div class="flex flex-center align-center service-header">
        <img src="../../assets/img/service-logo.png" alt="" />在线咨询
      </div>
      <div class="service-info">
        <div class="service-info-title">商务企业微信号</div>
        <div class="service-info-qr">
          <img src="../../assets/img/qr2.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 达人登录 -->
    <div class="dialog" v-if="dialogTableVisible" :title="titleValue">
      <div class="dialog-box">
        <div class="dialog-header">
          <div class="dialog-header-text">{{ titleValue }}</div>
          <img
            @click="closeImg"
            class="dialog-header-img"
            src="../../assets/mobileImg/mobile_36.png"
          />
        </div>
        <div class="dialog-content">
          <!-- <img :src="imgValue" class="dialogImg" alt="" /> -->
          <img src="../../assets/img/qr2.png" class="dialogImg" alt="">
          <div class="dialogText">扫码添加客服二维码</div>
        </div>
        <!-- <div class="footer">
          <div class="dialog-download" @click="downImg(imgValue)">
            下载小程序码
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import imgSrc from "@/assets/img/banner.png";
import imgSrc1 from "@/assets/img/login_person.jpg";
import imgSrc2 from "@/assets/img/login_com.jpg";
export default {
  data() {
    return {
      tagList: [
        {
          name: "首页",
          id: "#anchor1",
        },
        {
          name: "证书服务",
          id: "#anchor2",
        },
        {
          name: "任务看板",
          id: "#anchor3",
        },
        {
          name: "关于我们",
          id: "#anchor4",
        },
      ],
      tagIdx: 0,
      dialogTableVisible: false,
      titleValue: "达人登录",
      imgValue: null,
    };
  },
  methods: {
    clickTag(idx) {
      this.tagIdx = idx;
    },
    clickLinkFunc() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    clickImg(ind) {
      console.log(111111111);
      this.titleValue = ind == 1 ? "达人登录" : "服务市场登录";
      this.imgValue = ind == 1 ? imgSrc1 : imgSrc2;
      this.dialogTableVisible = true;
    },
    closeImg() {
      this.dialogTableVisible = false;
    },
    downImg(imgSrc) {
      var a = document.createElement("a");
      a.download = name || "pic";
      // 设置图片地址
      a.href = imgSrc;
      a.click();
    },
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.home-banner,
.home-js {
  position: relative;
  width: 100%;
  height: auto;
}
.home-banner-img,
.home-js-img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
.home-banner-logo {
  position: absolute;
  top: 36px;
  left: 12.5%;
  width: 152px;
  height: 48px;
}
.home-banner-logo img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.home-banner-tag {
  position: absolute;
  top: 36px;
  /* right: 12.5%; */
  right: 8%;
  width: auto;
  height: 48px;
}
.home-banner-tag-item {
  position: relative;
  width: auto;
  height: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-right: 48px;
}
.home-banner-tag-item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-banner-tag-item1 {
  font-weight: bold;
}
.home-banner-tag-item1::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 65px;
  height: 2px;
  background-color: #fff;
  content: "";
}
.home-banner-info {
  position: absolute;
  top: 250px;
  left: 12.5%;
  width: 43.33%;
  height: auto;
}
.home-banner-info-img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
.home-banner-info1 {
  position: absolute;
  top: 410px;
  left: 12.5%;
  width: auto;
  height: auto;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
}
.home-banner-info2 {
  width: 100%;
  position: absolute;
  display: flex;
  align-content: center;
  transform: translateY(-50%);
  justify-content: center;
}
.home-banner-info2 img {
  width: 31.25%;
  height: 22.23%;
  /*600px 200px  */
}
.home-banner-info2 img:first-of-type {
  margin-right: 16px;
}
.home-banner-info3 {
  width: 100%;
  /* background-color: red; */
  background-color: #fff;
  height: 50px;
}
.home-js-link {
  position: absolute;
  top: 253px;
  left: 50%;
  transform: translateX(-50%);
  width: 44.5%;
  height: 60px;
  /* background: rgba(0, 0, 0, 0.2); */
}
.home-js-link a {
  display: block;
  height: 100%;
}
.home-js-link-a1 {
  width: 33%;
}
.home-js-link-a2 {
  width: 21%;
}
.home-js-link-a3 {
  width: 35.5%;
}
.home-fotter {
  width: 100%;
  height: auto;
  padding: 36px 0px;
  background-color: #141621;
}
.home-fotter-item {
  width: 100%;
  height: auto;
  color: #8f9ba8;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.home-fotter-item-icon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  vertical-align: top;
}
.service {
  position: fixed;
  right: 48px;
  top: 25%;
  width: 204px;
  height: 328px;
  background: url("../../assets/img/service-bg.png") no-repeat center center;
  background-size: cover;
}
.service-header {
  width: 100%;
  height: 80px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.service-header img {
  width: 40px;
  height: 40px;
  vertical-align: top;
  margin-right: 8px;
}
.service-info {
  width: 100%;
  height: 200px;
  margin-top: 50px;
}
.service-info-title {
  width: 100%;
  height: auto;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.service-info-qr {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.service-info-qr img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
/* 弹框 */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(71, 70, 70, 0.37);
  z-index: 10000 !important;
}
.dialog-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 600px;
  height: 500px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.dialog-header {
  height: 110px;
  padding: 32px 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-header-text {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
}
.dialog-header-img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.dialog-content {
  width: 100%;
  text-align: center;
  padding: 48px 0 44px 0;
  /* border-bottom: 1px solid #eee; */
  border-top: 1px solid #eee;
}
.dialogImg {
  width: 280px;
  height: 280px;
  /* background-color: red; */
}
.dialogText {
  font-size: 14px;
  color: #666666;
}
.footer {
  height: 140px;
  padding: 20px 20px 20px 0;
  display: flex;
  justify-content: flex-end;
}
.dialog-download {
  background: #009d88;
  border-radius: 5px;
  padding: 10px 32px;
  box-sizing: border-box;
  font-size: 28px;
  color: #ffffff;
  width: 232px;
  height: 64px;
  cursor: pointer;
}
.home-banner-tag-login {
  font-size: 16px;
  padding: 0px 15px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ffffff;
  margin-top: 7px;
  cursor: pointer;
}
.aLogin {
  text-decoration: none;
}
</style>